import { Download, Search } from 'lucide-react';
import {
	LoaderFunctionArgs,
	useLoaderData,
	useLocation,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button/Button';
import YearMonthPicker from 'ui/components/DatePicker/YearMonthPicker';
import Flex from 'ui/components/Flex/Flex';
import PageHeader from 'ui/components/PageHeader';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import DataManagementAPI from '../../api/DataManagementAPI';
import CddMonitor from '../../components/CddMonitor';
import { YearMonthFragment } from '../../components/YearMonth/YearMonthFragment';
import { downloadFile } from 'utils/helpers/file';
import SplitButton from 'ui/components/SplitButton';
import { useState } from 'react';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const queryParams = new URL(request.url).searchParams;

	let year = Number(queryParams.get('reportDate.year'));
	let month = Number(queryParams.get('reportDate.month'));

	if (
		Number.isNaN(year) ||
		Number.isNaN(month) ||
		year < 2010 ||
		month < 1 ||
		month > 12
	) {
		return await DataManagementAPI.cddMonitor(null, null);
	}

	return await DataManagementAPI.cddMonitor(year, month);
};

export function useCurrentActionIsLoading() {
	const location = useLocation();
	const navigation = useNavigation();
	return (
		navigation.state !== 'idle' && navigation.formAction === location.pathname
	);
}

export function useCurrentLoaderIsLoading() {
	const location = useLocation();
	const navigation = useNavigation();
	return (
		navigation.state !== 'idle' &&
		navigation.location.pathname === location.pathname
	);
}

const ExportSplitButton = () => {
	const [isLoading, setIsLoading] = useState(false);

	const exportCddMonitor = async (exportType: 'export' | 'export-all') => {
		setIsLoading(true);

		let year = null;
		let month = null;
		if (exportType === 'export') {
			const queryParams = new URLSearchParams(window.location.search);
			year = queryParams.get('reportDate.year');
			month = queryParams.get('reportDate.month');
		}

		const exportResponse = await DataManagementAPI.cddMonitorDownload(
			year ? Number(year) : null,
			month ? Number(month) : null,
			exportType
		);

		downloadFile(exportResponse.url, exportResponse.fileName);

		setIsLoading(false);
	};

	return (
		<SplitButton
			variant="secondary"
			size="small"
			defaultActionId="error-only"
			isLoading={isLoading}
			loadingText="Exporting..."
			actions={[
				{
					id: 'export',
					label: 'Export',
					icon: Download,
					onClick: () => exportCddMonitor('export'),
				},
				{
					id: 'export-all',
					label: 'Export All',
					icon: Download,
					onClick: () => exportCddMonitor('export-all'),
				},
			]}
		/>
	);
};

export function CddMonitorPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const pageIsLoading = useCurrentLoaderIsLoading();

	return (
		<>
			<div className="content">
				<PageHeader
					title={
						<>
							{'CDD Monitor - '}{' '}
							<YearMonthFragment yearMonth={data.yearAndMonth.yearMonth} />{' '}
						</>
					}
				>
					<ValidatedForm>
						<Flex gap={12} alignItems="center">
							<YearMonthPicker
								name="reportDate"
								initialValue={data.yearAndMonth}
							/>
							<Button type="submit" icon={Search} isLoading={pageIsLoading}>
								Search
							</Button>
						</Flex>
					</ValidatedForm>

					<ExportSplitButton />
				</PageHeader>
				<CddMonitor
					months={data.months}
					selectedMonth={data.yearAndMonth}
					yearsAndMonths={data.yearAndMonths}
				/>
			</div>
		</>
	);
}

export const CDD_MONITOR_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <CddMonitorPage />,
	handle: {
		breadcrumbs: () => {
			return { label: 'CASS HotFile Monitor' };
		},
	},
};
