import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { LabeledValue } from 'utils/types/common';

declare interface ErrorCodeSummary {
	[key: string]: number;
}

declare interface AlertCodeSummary {
	[key: string]: number;
}

export type JobBase = {
	id: number;
	status: string;
	errorCodesSummary?: ErrorCodeSummary;
	alertCodesSummary?: AlertCodeSummary;
	startedAtUtc: Date;
	updatedAtUtc?: Date;
	completedAtUtc?: Date;
};

export type JobStep = JobBase & {
	name: IdAndLabel;
	data?: object;
};

type IdAndLabel = {
	id: string;
	label: string;
};

export type Job = JobBase & {
	category: IdAndLabel;
	type: IdAndLabel;
	name: string;
	file?: {
		id: number;
		name: string;
		size: number;
		receivedAtUtc: Date;
		receivedFrom: string;
	};
	errorCodes?: string[];
	alertCodes?: string[];
	steps: JobStep[];
};

export type GetJobsResponse = {
	jobs: {
		items: Job[];
		pageSize: number;
		page: number;
		total: number;
	};
	categories: LabeledValue[];
	status: string[];
	types: LabeledValue[];
	searchParameters: {
		filename?: string;
		category?: string;
		type?: string;
		status?: string;
	};
};

type ErrorCodeDescriptor = {
	title: string;
	description: string;
};

export type GetErrorCodesResponse = {
	errorCodes: Record<string, ErrorCodeDescriptor>;
};

class DataMonitoringAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint + '/data-monitor';

	static getJobs(params?: URLSearchParams) {
		return DataMonitoringAPI.get<GetJobsResponse>(`/job?${params}`);
	}

	static getErrorCodes(fileType?: string) {
		const params = new URLSearchParams();

		if (fileType) {
			params.append('fileType', fileType);
		}

		return DataMonitoringAPI.get<GetErrorCodesResponse>(
			`/error-codes?${params}`
		);
	}
}

export default DataMonitoringAPI;
