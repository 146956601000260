import classNames from 'classnames';
import { AlertCircle } from 'lucide-react';
import React, { ComponentProps, ForwardedRef } from 'react';
import { flattenErrors } from 'utils/zod/zodErrors';
import Tooltip from '../Tooltip';
import useValidation from '../ValidatedForm/useValidation';

interface TableInputProps extends ComponentProps<'input'> {
	name?: string;
	validationPath?: string;
}

const TableInput = (
	{ className, name, validationPath, onChange, ...restProps }: TableInputProps,
	ref: ForwardedRef<HTMLInputElement>
) => {
	const { errorTree, revalidate } = useValidation(validationPath ?? name);
	const flattenedErrors = flattenErrors(errorTree);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		revalidate();
		onChange?.(event);
	};

	return (
		<div className="table__input">
			<input
				className={classNames('table__input-control', className)}
				name={name}
				onChange={handleChange}
				ref={ref}
				{...restProps}
			/>
			{flattenedErrors && flattenedErrors.length > 0 && (
				<Tooltip>
					<Tooltip.Trigger>
						<AlertCircle className="table__input-indicator" size={16} />
					</Tooltip.Trigger>
					<Tooltip.Content>
						<ul className="table__input-errors">
							{flattenedErrors.map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Tooltip.Content>
				</Tooltip>
			)}
		</div>
	);
};

export default React.forwardRef(TableInput);
