import { createColumnHelper } from '@tanstack/react-table';
import { Plus, Trash2 } from 'lucide-react';
import { useState } from 'react';
import Button from 'ui/components/Button';
import DatePicker from 'ui/components/DatePicker/DatePicker';
import Flex from 'ui/components/Flex';
import Table from 'ui/components/Table';
import TableInput from 'ui/components/Table/TableInput';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import { Identifiable } from 'utils/types/common';
import { EBookingCode } from '../../api/SubscriptionAPI';

type IdentifiableEBookingCode = Identifiable<EBookingCode, number>;

type EBookingCodesTableContext = {
	onRemove: (id: number) => void;
	onRevalidate: () => void;
	name: string;
};

const columnHelper = createColumnHelper<IdentifiableEBookingCode>();

const columns = [
	columnHelper.accessor('code', {
		header: 'Code',
		cell: (info) => {
			const context = info.table.options.meta as EBookingCodesTableContext;
			const name = `${context.name}.${info.row.index}.code`;
			return (
				<TableInput
					defaultValue={info.getValue()}
					name={name}
					placeholder="Code"
					data-testid="ebooking-code"
				/>
			);
		},
	}),
	columnHelper.accessor('effectiveFrom', {
		header: 'Effective From',
		cell: (info) => {
			const context = info.table.options.meta as EBookingCodesTableContext;
			const name = `${context.name}.${info.row.index}.effectiveFrom`;
			return (
				<DatePicker
					name={name}
					initialValue={info.getValue()}
					placeholder="Date"
					onChange={context.onRevalidate}
					customInput={<TableInput validationPath={name} />}
				/>
			);
		},
	}),
	columnHelper.accessor('effectiveTo', {
		header: 'Effective To',
		cell: (info) => {
			const context = info.table.options.meta as EBookingCodesTableContext;
			const name = `${context.name}.${info.row.index}.effectiveTo`;

			return (
				<DatePicker
					name={name}
					initialValue={info.getValue()}
					placeholder="Date (Optional)"
					onChange={context.onRevalidate}
					customInput={<TableInput validationPath={name} />}
				/>
			);
		},
	}),
	columnHelper.display({
		id: 'actions',
		header: '',
		cell: (info) => (
			<Button
				size="tiny"
				icon={Trash2}
				variant="secondary"
				onClick={() =>
					(info.table.options.meta as EBookingCodesTableContext).onRemove(
						info.row.original.id
					)
				}
			>
				Remove
			</Button>
		),
	}),
];

interface EBookingCodesEditorProps {
	initialValue?: Array<EBookingCode>;
	name: string;
}

const EBookingCodesEditor = ({
	initialValue,
	name,
}: EBookingCodesEditorProps) => {
	const { revalidate } = useValidation(name);

	const [entries, setEntries] = useState<Array<IdentifiableEBookingCode>>(
		initialValue ? initialValue.map((itm, idx) => ({ ...itm, id: idx })) : []
	);

	const handleAdd = () => {
		setEntries((prev) => [
			...prev,
			{
				id: prev.length,
				code: '',
				effectiveFrom: null,
				effectiveTo: null,
			},
		]);

		revalidate();
	};

	return (
		<Flex direction="column" gap={4}>
			<Table
				emptyText="There are no e-booking codes"
				emptyHint="Click 'Add' to add a new code"
				columns={columns}
				data={entries}
				identifierKey="id"
				tableOptions={{
					meta: {
						onRemove: (id: number) => {
							setEntries((prev) => prev.filter((itm) => itm.id !== id));
							revalidate();
						},
						onRevalidate: revalidate,
						name,
					} satisfies EBookingCodesTableContext,
				}}
			/>
			<div>
				<Button
					icon={Plus}
					size="small"
					type="button"
					variant="secondary"
					onClick={handleAdd}
					data-testid="ebooking-code-add-button"
				>
					Add
				</Button>
			</div>
		</Flex>
	);
};

export default EBookingCodesEditor;
